import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button, Container } from '@material-ui/core';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Ribbon from '../../components/Ribbon';
import CardWide from '../../components/Cards/CardWide';
import Maze from '../../components/Maze';
import { sanitairData } from '../../lib/overviewData';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "sanitair/sanitair-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "sanitair/sanitair-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages1: file(relativePath: { eq: "sanitair/sanitair-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages2: file(relativePath: { eq: "sanitair/sanitair-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const Sanitair = ({ data, path, location }) => {
  const seo = {
    title: `E S I - alle sanitair producten`,
    description: `E S I - alle sanitair producten die E S I - Installatietechniek B.V. aanbiedt om u te helpen.`,
    pathname: path,
    location,
  };

  const GridImages = [data.gridImages1, data.gridImages2];

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `E S I - Sanitair`,
    pitchLine: `E S I - Bij ons kunt u terecht voor al uw werkzaamheden aan uw sanitaire voorzieningen.`,
  };

  const textTop = {
    header: `Bij ons kunt u terecht voor al uw werkzaamheden aan uw sanitaire voorzieningen.`,
    textWithLink: () => (
      <>
        <p>
          Tegenwoordig is de keuze op het gebied van sanitaire installaties erg
          groot. Van zeer eenvoudige tot luxueuze douches, ligbaden, wastafels
          en toiletvoorzieningen. Bij ons kunt u terecht voor al uw
          werkzaamheden aan uw sanitaire voorzieningen. Zo kunt u de volledige
          installatie en reparatie met een gerust hart aan ons overlaten.
        </p>
      </>
    ),
  };
  const textBottom = {
    header: `Even persoonlijk contact?`,
    textWithLink: () => (
      <>
        <p>
          Aarzel dan zeker niet om te bellen. Dan proberen we samen uw problemen
          op te lossen
        </p>
        <div className='hidden-sm hidden-md hidden-lg'>
          <a href='tel:0642090304'>
            <Button
              role='button'
              variant='contained'
              className='btn btn--secondary heading__button mt-4x '
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </a>
        </div>
        <AniLink fade to='/contact' className='hidden-xs'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </AniLink>
      </>
    ),
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        location={location}
      />
      <Ribbon
        fluid={ribbon.image}
        heading={ribbon.heading}
        pitchLine={ribbon.pitchLine}
      />
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle={textTop.header}
          data-sal='slide-left'
        >
          <>
            {textTop.inner &&
              textTop.inner.map((text, i) => <p key={i}>{text}</p>)}
            {textTop.textWithLink && textTop.textWithLink()}
          </>
          <></>
        </CardWide>
        <Maze data={sanitairData} path={path} gridImages={GridImages} />
      </Container>
    </Layout>
  );
};

Sanitair.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Sanitair;
